/**
* 25. Sections
* ----------------------------------------------------------------------- *
*/
.section {
	position: relative;
	padding: 8rem 0;
	z-index: 1;
    
    &.section-sm{
        padding: 4rem 0;
    }
}

.section.ui-showcase-section{
	& div[class^="col-"], div[class*=" col-"]{
		&:nth-child(1){
			@include media-breakpoint-down(md){
				& img{
					float: right;
					margin-right: -4rem;
				}
			}
		}
		&:nth-child(2){
			@media (min-width: 992px) {
				padding-left: 2rem;
			}
			@media (min-width: 1200px) {
				padding-left: 0;
			}
		}
	}
}

.section {
	& .section-heading {
		margin-bottom: 4rem;
		& .icon {
			display: inline-block;
			font-size: $font-size-h1;
			margin-bottom: 2rem;
		}
		& .heading {
			margin-top: 0;
			margin-bottom: 1rem;
		}
		& .paragraph {
			display: inline-block;
			max-width: 500px;
			font-size: 1.1rem;
			margin-bottom: 1rem;
		}
		&.center {
			text-align: center;
			& .paragraph {
				margin: 0 auto;
			}
		}
		@include breakpoint(sm) {
			& .heading, & .paragraph {
				margin-bottom: 1.25rem;
			}
		}
		@include breakpoint(md) {
			& .heading, & .paragraph {
				margin-bottom: 1.5rem;
			}
		}
		@include breakpoint(lg) {
			& .heading, & .paragraph {
				margin-bottom: 1.75rem;
			}
		}
	}
}

.section {
	&.bg-overlay {
		background-position: center center;
		background-size: cover;
		& .container {
			position: relative;
			z-index: 2;
		}
		&::before {
			display: block;
			content: '';
			opacity: 0.8;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 1;
		}
	}
}

.section {
	&.waves-layer {
		& .container {
			position: relative;
			z-index: 2;
		}
		&::after {
			display: block;
			content: '';
			position: absolute;
			bottom: -1px;
			left: -25%;
			right: -25%;
			height: 100%;
			background: url(../img/svg-layers/waves-layer-a.svg) bottom no-repeat;
			background-size: contain;
			opacity: 0.75;
			z-index: 1;
			@include breakpoint(md) {
				left: -15%;
				right: -15%;
			}
			@include breakpoint(lg) {
				left: -12%;
				right: -12%;
			}
			@include breakpoint(xl) {
				left: -9%;
				right: -9%;
			}
		}
	}
}

.ui-action-section.ui-section-tilt {
	margin: -4rem 0;
	padding: 16rem 0;
	z-index: 0;
	&::before, &::after {
		display: block;
		content: '';
		position: absolute;
		left: -8rem;
		right: -8rem;
		height: 16rem;
		background-color: #FFF;
		@include rotate(-10);
		z-index: -1;
		@include breakpoint(md) {
			@include rotate(-8.5);
		}
		@include breakpoint(lg) {
			@include rotate(-5.5);
		}
	}
	&::before {
		top: -8rem;
	}
	&::after {
		bottom: -8rem;
	}
	@include media-breakpoint-down(md) {
		margin: -8rem 0 0 0;
	}
	& .text-block {
		margin-top: 2rem;
		@include media-breakpoint-up(md) {
			display: inline-block;
			margin-top: 6rem;
		}
	}
	& .img-block {
		@include media-breakpoint-down(sm) {
			margin-bottom: -10rem;
		}
	}
}

.ui-action-section {
	& .text-block {
		@include media-breakpoint-down(sm){
			text-align: center;
		}
	}
	& .section-heading {
		margin-bottom: 0;
	}
	& .img-block {
		text-align: center;
		& img {
			position: relative;
			margin-top: 4rem;
			margin-bottom: -12rem;
			z-index: 2;
			@include media-breakpoint-up(md) {
				display: inline-block;
				margin-top: -6rem;
				margin-bottom: -12rem;
			}
		}
	}
    &.action-section-simple{
        & .actions{
            margin-top: 2rem;
			@include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
}