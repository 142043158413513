/**
* 22. Logos Cloud
* ----------------------------------------------------------------------- *
*/

.ui-logos-cloud{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;

	& > div{
		width: 100px;
		height: 100px;
		margin: 1rem;
		overflow: hidden;
		border-radius: 100%;
		background-color: #fff;
		color: $gray;
        transition: transform .35s cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow .35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		@include shadow(xl);

		display: flex;
		justify-content: center;
		align-items: center;

        &:nth-child(2) {
          break-after: always;
        }

		& img{
			max-width: 100%;
			width: 100%;
			height: auto;
		}

		&[data-size="3"] {
			font-size: .9rem;
		}
		&[data-size="4"] {
			font-size: 1rem;
		}
		&[data-size="5"] {
			font-size: 1.3rem;
		}
		&[data-size="6"] {
			font-size: 1.6rem;
		}
		&[data-size="7"] {
			font-size: 2rem;
		}
		&[data-size="8"] {
			font-size: 2.3rem;
		}
		&[data-size="9"] {
			font-size: 2.6rem;
		}
		&[data-size="10"] {
			font-size: 3rem;
		}

        &:hover{
            @include shadow(xxl);
            transform: scale(1.1);
        }
	}

    @media (max-width:991px){
        margin-top: 4rem;
    }
}
