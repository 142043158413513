/**
* 43. Contact Page
* ----------------------------------------------------------------------- *
*/

.form-group .row > .col-sm-6:nth-child(1) {
  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
}

.contact-section .form-card {
  margin-top: 4rem;
  @include media-breakpoint-up(md) {
    margin-top: -2rem;
  }
}

.help-block.form-error {
  display: block;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
  font-size: 0.875rem;
  color: $danger;
  text-shadow: 0 0 15px #000;
}

.help-block.form-success {
  background-color: rgba($lime, 0.25);
  font-weight: 600;
  text-align: center;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  display: block;
}
