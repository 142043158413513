/**
* 34. Blog
* ----------------------------------------------------------------------- *
*/

.ui-blog {
  padding: 4rem 0;
  & .blog-section {
    margin-bottom: 4rem;
    & .section-label {
      color: $gray-light;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }
  & .blog-sidebar {
    @include clearfix();
  }
  // Medium Screens
  @include media-breakpoint-up(lg) {
    display: table;
    width: 100%;
    padding: 5rem 0;
    & .blog-body,
    & .blog-sidebar {
      display: table-cell;
    }
    & .blog-sidebar {
      padding-left: 5rem;
      width: 350px;
      vertical-align: top;
    }
    & .blog-sidebar .ui-widget {
      width: 200px;
    }
  }
  // X-Larger Screens
  @include media-breakpoint-up(xl) {
    padding: 6rem 0;
    & .blog-sidebar {
      padding-left: 6rem;
      width: 400px;
    }
  }
}

.ui-blog.sidebar-left {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row-reverse;

    & .blog-sidebar {
      padding-left: 0;
      margin-right: 6rem;
    }
  }
}

.ui-blog-grid {
  & .post-item {
    margin-bottom: 2rem;
    & .card-image {
      // height: 180px;
      // @include media-breakpoint-up(sm) {
      //   height: 200px;
      // }
      // @include media-breakpoint-up(md) {
      //   height: 232px;
      // }
      & .ui-cover-img {
        max-width: none;
      }
    }
    & .card-body {
      height: 6.8rem;
    }
  }
}
.ui-blog-list {
  & .post-item {
    border-bottom: 1px solid $divider-color;
    padding-bottom: 1.5rem;
    margin-bottom: 4rem;
    & .heading {
      margin-bottom: 0.35rem;
    }
    & .post-date {
      margin-bottom: 1.5rem;
    }
    & .post-meta {
      margin-bottom: 1rem;
      & div[class^="col-"],
      & div[class*=" col-"] {
        @media (max-width: 413px) {
          width: 100%;
          float: none;
        }
      }
      & .row > div:nth-last-child(1) {
        height: 40px;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        & > div {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          font-size: 0.95rem;
          text-align: left;
        }
        @media (max-width: 413px) {
          display: none;
        }
      }
      & .post-author {
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        & span {
          display: inline-block;
          vertical-align: middle;
        }
        & .avatar {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          & img {
            width: 40px;
            height: 40px;
          }
          @media (max-width: 413px) {
            width: 32px;
            height: 32px;
            & img {
              width: 32px;
              height: 32px;
            }
          }
        }
        @media (max-width: 413px) {
          height: 32px;
        }
      }
      & .icon {
        padding-right: 0.5rem;
        color: $primary;
        font-size: $font-size-h5;
        vertical-align: middle;
      }
    }
    & .post-body {
      padding: 1.8rem 2rem 2rem 2rem;
    }
    & .post-text {
      margin-bottom: 1.5rem;
      & p {
        margin-bottom: 0;
      }
      & .ui-turncate-text {
        height: 4.2rem;
      }
    }
    .post-footer {
      & .row > div:nth-last-child(1) {
        height: 40px;
        text-align: right;
        & > div {
          display: inline-block;
          height: 40px;
          margin-right: 0.5rem;
          line-height: 40px;
          text-align: left;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
  & .post-image {
    height: 280px;
    @include media-breakpoint-up(sm) {
      height: 300px;
    }
    @include media-breakpoint-up(md) {
      height: 340px;
    }
  }
}

.ui-card.post-item {
  height: calc(100% - 2rem);
  & .card-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
    & .heading {
      margin-bottom: 0.25rem;
    }
    & small {
      font-size: 0.9rem;
    }
  }
  & .card-body {
    padding: 1rem 1.5rem 1rem 1.5rem;
    & p {
      margin-bottom: 0;
    }
  }
  & .card-footer {
    padding: 0 1.5rem 1.5rem 1.5rem;
    & .post-meta {
      font-size: 0.9rem;
      & .row {
        margin-top: 1rem;
      }
      & .row > div:nth-last-child(1) {
        height: 26px;
        text-align: right;
        & > div {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
        }
      }
    }
    & .post-author {
      height: 26px;
      & span {
        display: inline-block;
        vertical-align: middle;
      }
      & .avatar {
        width: 26px;
        height: 26px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 0.25rem;
        & img {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}

.post-image {
  background-size: cover;
  background-position: center center;
  border-radius: $border-radius;
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.ui-blog-post {
  & .heading {
    margin-bottom: 1.5rem;
  }
  & .post-image {
    margin-bottom: 2rem;
  }
  .post-footer {
    padding-bottom: 4rem;
    border-bottom: 1px solid $divider-color;
    margin-bottom: 4rem;
    margin-top: 2rem;
    & .heading {
      text-transform: uppercase;
      margin-bottom: 1rem;
      & small {
        font-weight: 600;
      }
    }
    & .post-tags {
      margin-top: 2rem;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
        text-align: right;
      }
    }
  }
}

.ui-hero.ui-post-hero {
  padding: 6rem 0 6rem 0;
  & .post-meta {
    padding: 4rem 0 2rem 0;
    @include clearfix();
    & > div {
      display: block;
      float: left;
      height: 22px;
      margin-right: 1.5rem;
      color: #fff;
      font-weight: 500;
      font-size: 0.9rem;
      & span,
      & span .icon {
        display: inline-block;
        line-height: 22px;
        vertical-align: middle;
      }
      & a {
        color: #fff;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          border-bottom: 2px dotted rgba(#fff, 0.5);
        }
      }
      & .icon {
        color: #fff;
        font-size: 1.05rem;
        margin-right: 0.25rem;
      }
    }
  }
}

.comment-reply {
  padding-left: 3rem;
}
.comment {
  display: flex;
  position: relative;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  &.border {
    border: none;
    border-bottom: 1px solid $divider-color;
  }
  // Comment Date
  & .date-posted {
    position: absolute;
    top: 0;
    right: 0;
  }
  // Comment Text
  & .text {
    padding-left: 2rem;
    & .heading {
      margin-bottom: 0.5rem;
    }
    & p {
      margin-bottom: 0.5rem;
    }
  }
  // Comment Avatar
  & .avatar {
    width: 60px;
    & img {
      border-radius: 100%;
      width: 60px;
      height: auto;
    }
  }
  // Comment Actions "Like + Share"
  & .comment-actions {
    & a {
      display: inline-block;
      color: $gray-light;
      margin-right: 0.5rem;
      font-size: 0.8rem;
      &:hover {
        color: $primary;
      }
    }
  }
  // Comment Meta Data
  .comment-meta {
    & li {
      display: inline-block;
      margin-right: 0.75rem;
      font-weight: 400;
      font-size: 0.75rem;
      color: $gray;
      & a {
        font-weight: 400;
        color: $gray-darker;
      }
      & .icon {
        color: $gray-darker;
      }
    }
  }
}
.ui-comment-form {
  & a {
    border: none;
    font-size: 1.1rem;
  }
}

.ui-author-card {
  padding: 1rem;
  @include media-breakpoint-up(md) {
    padding: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }
  & .inner {
    display: table;
    width: 100%;
  }
  & .block {
    display: table-cell;
    &:nth-child(1) {
      width: 110px;
      text-align: center;
      vertical-align: middle;
      @include media-breakpoint-up(md) {
        width: 120px;
      }
      @include media-breakpoint-up(lg) {
        width: 130px;
      }
    }
    &:nth-last-child(1) {
      padding-left: 1rem;
      vertical-align: top;
      @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        padding-left: 2rem;
      }
    }
  }
  & .avatar {
    width: 60px;
    margin: 0 auto 1rem auto;
    border-radius: 100%;
    overflow: hidden;
    & img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-up(md) {
      width: 70px;
    }
    @include media-breakpoint-up(lg) {
      width: 80px;
    }
  }
  & .heading {
    margin-bottom: 0.25rem;
  }
  & .sub-heading {
    font-size: 0.9rem;
  }
  & .paragraph {
    margin-bottom: 0;
  }
  & .social-links {
    & a {
      color: #fff;
      margin-right: 0.35rem;
      font-size: 1.1rem;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}

.ui-widget {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid lighten($divider-color, 5%);
  & .ui-widget-title {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: $gray-light;
  }
}
.ui-basic-widget {
  & .image {
    border-radius: $border-radius;
    overflow: hidden;
    & img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  & .heading {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    font-size: 1rem;
  }
  & .paragraph {
    font-size: 0.9rem;
  }
}
.ui-posts-widget {
  & .post-item {
    display: block;
    position: relative;
    padding-left: 66px;
    height: 66px;
    color: $gray;
    &:hover,
    &:hover .heading {
      color: $primary;
    }
  }
  & .post-image {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    text-align: center;
    & img {
      display: inline-block;
      width: auto;
      height: auto;
    }
  }
  & .post-body {
    display: flex;
    align-items: center;
    height: 100%;
    & > .inner {
      width: 100%;
    }
    & .heading,
    & p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    & .heading {
      margin-top: 0;
      margin-bottom: 0.25rem;
      font-size: 1rem;
    }
    & p {
      margin-bottom: 0;
      font-size: 0.9rem;
    }
  }
}
.ui-instagram-widget {
  @include clearfix();
  & a {
    display: block;
    width: 33.33333%;
    float: left;
    margin-bottom: 1rem;
  }
  & img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
.ui-instagram-widget,
.ui-tags-widget {
  @include media-breakpoint-down(sm) {
    width: 48%;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.ui-instagram-widget {
  @include media-breakpoint-down(sm) {
    float: right;
  }
}
.ui-tags-widget {
  @include media-breakpoint-down(sm) {
    float: left;
  }
}

.ui-tags-widget .tag {
  display: inline-block;
  padding: 0.2rem 0.75rem;
  border-radius: $border-radius;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  font-size: 0.9rem;
  border: 1px solid $divider-color;
  @include transition(color 0.25s ease-out, background-color 0.25s ease-out);
  &.active,
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.ui-search-bar {
  position: relative;
  input {
    display: block;
    width: 100%;
    border: none;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
  }
  &::after {
    display: block;
    position: absolute;
    content: "";
    top: 50%;
    left: 0.75rem;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDQ2MCA0NjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ2MCA0NjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojODA4OUZGO30KPC9zdHlsZT4KPGc+Cgk8Zz4KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzg0LDE5MkMzODQsODYsMjk4LDAsMTkyLDBDODYsMCwwLDg2LDAsMTkyczg2LDE5MiwxOTIsMTkyQzI5OCwzODQsMzg0LDI5OCwzODQsMTkyeiBNMTkyLDMzNgoJCQljLTc5LjQsMC0xNDQtNjQuNi0xNDQtMTQ0UzExMi42LDQ4LDE5Miw0OGM3OS40LDAsMTQ0LDY0LjYsMTQ0LDE0NFMyNzEuNCwzMzYsMTkyLDMzNnoiLz4KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDQ5LjUsNDEyLjRsLTg2LjYtODYuNmMtOS42LDE0LjktMjIuMiwyNy41LTM3LjEsMzcuMWw4Ni42LDg2LjZjMTAuMiwxMC4yLDI2LjksMTAuMiwzNy4xLDAKCQkJQzQ1OS43LDQzOS4yLDQ1OS43LDQyMi42LDQ0OS41LDQxMi40eiIvPgoJPC9nPgo8L2c+Cjwvc3ZnPgo=");
    background-size: cover;
    z-index: 1;
  }
}
