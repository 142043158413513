.youtube-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.youtube-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
