.cookie-notice {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  z-index: 99999;
  font-size: 0.75rem;
}

.cookie-notice__panel {
  width: 100%;
  background: #333;

  h6 {
    color: #fff;
  }
}
