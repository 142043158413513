.ui-card {
  &.post-item {
    .card-header,
    .card-body,
    .card-footer {
      // Prevent wrapping <a> from changing the colour
      color: $text-color;
    }
  }
}

.ui-blog-list {
  .post-item {
    padding-bottom: 0;

    .post-text,
    .post-author,
    .post-tags {
      // Prevent wrapping <a> from changing the colour
      color: $text-color;
    }

    a:hover .heading {
      text-decoration: underline;
    }

    .post-body {
      padding-left: 0;
      padding-right: 0;
    }

    .post-meta {
      margin-bottom: 0;
    }

    .post-text {
      // Intentional typo on next line (from theme)
      .ui-turncate-text {
        height: auto;
        max-height: 4.2rem;
      }
    }
  }
}

.ui-blog-post {
  .post-footer {
    margin: 2rem 0;
    padding: 0 0 2rem;

    .post-tags {
      text-align: left;
    }
  }
}
