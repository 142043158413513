// Mixins
@import "mixins/animations";
@import "mixins/breakpoints";
@import "mixins/colors";
@import "mixins/shadows";
@import "mixins/transform";
@import "mixins/flexbox";
// Libraries
@import "libs/font-awesome/font-awesome";
@import "libs/owl.carousel/owl.carousel";
@import "libs/owl.carousel/owl.theme.default";
@import "libs/simple-line-icons/simple-line-icons";
@import "libs/slider-pro/slider-pro";
@import "libs/prism/prism";
// Utilities
@import "utilities/animations";
@import "utilities/colors";
@import "utilities/media";
@import "utilities/shadows";
@import "utilities/spacing";
@import "utilities/typography";
// Core
@import "core/base";
// Components
@import "components/accordion";
@import "components/buttons";
@import "components/cards";
@import "components/collapsible-nav";
@import "components/dropdowns";
@import "components/footer";
@import "components/hero";
@import "components/icon-blocks";
@import "components/logos-cloud";
@import "components/modal";
@import "components/navbar";
@import "components/sections";
@import "components/tabs";
@import "components/video";
@import "components/showcase-blocks";
@import "components/steps";
// Modules
@import "modules/accordion-showcase";
@import "modules/app-screens";
@import "modules/app-showcase";
@import "modules/app-stats";
@import "modules/blog";
@import "modules/clients-logos";
@import "modules/device-slider";
@import "modules/pricing-cards";
@import "modules/tabbed-showcase";
@import "modules/testimonials";
@import "modules/coming-soon";
@import "modules/pricing-table";
@import "modules/api-docs";
@import "modules/contact-page";
@import "modules/404-page";
