/*  43 :: TABBED SHOWCASE*/

.ui-tabbed-showcase{
	// Small Screens Only
	@media (max-width: 739px) {
		& > div{
			&:nth-child(1){
				margin-bottom: 4rem;	
			}
		}
	}
}