.ui-hero {
    overflow: hidden;


    .ui-logos-cloud {
        position: absolute;
        top: 0;
        left: 50%;

        & > div {
            position: absolute;
        }
    }
}
