/**
* 01. Bootstrap 4.0.0
* ----------------------------------------------------------------------- *
*
* Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
* Copyright 2011-2017 The Bootstrap Authors
* Copyright 2011-2017 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "functions";
@import "variables";
@import "mixins";
@import "reboot";
@import "type";
@import "grid";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "list-group";
@import "pagination";
@import "badge";
@import "tables";
@import "code";
@import "alert";
//@import "custom-forms";
@import "images";
@import "nav";
@import "navbar";
@import "card";
@import "modal";
@import "utilities";
