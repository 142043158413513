/*!
 * Applify v1.0.1 (http://codeytech.com/site-templates/applify/)
 * Author: Codeytech (http://codeytech.com/)
 */

/*!
 * CSS Table of contents
 *
 * --- Libraries
 * 01. Bootstrap 4.0.0
 * 02. Font Awesome
 * 03. Simple Line Icons
 * 04. Owl Carousel
 * 05. Slider Pro
 * 06. Prism
 *
 * --- Utilities
 * 07. Animations
 * 08. Colors
 * 09. Media
 * 10. Shadows
 * 11. Spacing
 * 12. Typography
 *
 * --- Core Styling
 * 13. Base
 *
 * --- UI Components
 * 14. Accordion
 * 15. Buttons
 * 16. Cards
 * 17. Collapsible Nav
 * 18. Drop-downs
 * 19. Footer
 * 20. Hero
 * 21. Icon Blocks
 * 22. Logos Cloud
 * 23. Modal
 * 24. Nav Bar
 * 25. Section
 * 26. Tabs
 * 27. Video
 * 28. Showcase Blocks
 * 29. Steps
 *
 * --- Modules
 * 30. Accordion Showcase
 * 31. App Screens
 * 32. App Showcase
 * 33. App Stats
 * 34. Blog
 * 35. Client Logos
 * 36. Device Slider
 * 37. Pricing Cards
 * 38. Tabbed Showcase
 * 39. Testimonials
 * 40. Coming Soon
 * 41. Pricing Table
 * 42. Api Docs
 * 43. Contact Page
 * 44. 404 Page
 */

$brand-color: #00dfc9;
$brand-secondary: #ddd;
$brand-accent: #ff00ea;

// Tier colours
$free: #6c757d;
$basic: $brand-color;
$premium: #ffc107;

$headings-margin-bottom: 1.5rem;

$theme-colors: (
  "brand": $brand-color,
  "accent": $brand-accent,
  "free-tier": $free,
  "basic-tier": $basic,
  "premium-tier": $premium,
  "schools": $brand-accent,
) !default;

// Applify Config
@import "applify/core/configuration";
// Bootstrap
@import "bootstrap-4.0.0-beta/bootstrap-custom";
// Applify
@import "applify/applify";

@import "squiggle/post-item";
@import "squiggle/cookie-notice";
@import "squiggle/youtube";
@import "squiggle/hero";

.btn-primary,
.btn-secondary,
.btn-premium-tier {
  &:hover {
    color: #fff;
  }
}

.small-quote {
  font-size: 60%;
}

.ui-pricing-card .card-header.bg-basic-tier {
  .heading,
  .sub-heading,
  .price {
    color: #fff;
  }
}

.ui-pricing-card .card-header.bg-premium-tier {
  .sub-heading,
  .price {
    color: #414c5a;
  }
}

.intro-image {
  text-align: center;
}

.intro-image-item {
  max-width: 1000px;
  margin: 0 auto;
}

.intro-image-caption {
  padding: 10px;
  margin: 10px auto;
  min-width: 300px;
  max-width: 450px;
  font-size: 0.75rem;

  h3 {
    font-size: 1.25rem;
  }
}

.intro-image-button {
  font-size: 0.85rem;
}

.ui-gradient-peach {
  .paragraph {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.alert-sign-up {
  @include alert-variant(
    // bg
    theme-color-level("premium-tier", -10),
    // border
    theme-color-level("premium-tier", 0),
    #414c5a
  );
  border-style: dotted;
}

.alert-existing-schools {
  @include alert-variant(
    // bg
    theme-color-level("light", 0),
    // border
    theme-color-level("light", 2),
    #414c5a
  );
  border-style: dotted;
}

.alert-not-translated {
  @include alert-variant(
    // bg
    theme-color-level("light", 0),
    // border
    theme-color-level("light", 2),
    #414c5a
  );
  border-style: dotted;
}

.game-button {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  font-size: 1.5em;
  line-height: 1.25;
  text-align: center;
  background: #ff00e9;
  color: #fff;
}

.hidden-if-first-child {
  &:first-child {
    display: none !important;
  }
}

// Responsive embed for YouTube videos
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
