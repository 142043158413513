/**
* 11. Spacing
* ----------------------------------------------------------------------- *
*/
.pt-0, .section.pt-0{
	padding-top: 0;
}
.pt-1, .section.pt-1{
	padding-top: 1rem;
}
.pt-2, .section.pt-2{
	padding-top: 2rem;
}
.pt-3, .section.pt-3{
	padding-top: 3rem;
}
.pt-4, .section.pt-4{
	padding-top: 4rem;
}
.pt-8, .section.pt-8{
	padding-top: 8rem;
}
.pt-6, .section.pt-6{
	padding-top: 6rem;
}
.pb-0, .section.pb-0{
	padding-bottom: 0;
}
.pb-1, .section.pb-1{
	padding-bottom: 1rem;
}
.pb-2, .section.pb-2{
	padding-bottom: 2rem;
}
.pb-3, .section.pb-3{
	padding-bottom: 3rem;
}
.pb-4, .section.pb-4{
	padding-bottom: 4rem;
}
.pb-6, .section.pb-6{
	padding-bottom: 6rem;
}
.pb-8, .section.pb-8{
	padding-bottom: 8rem;
}
.ui-card .card-header.pb-0{
	padding: 2rem 2rem 0 2rem;
}
.mt-0{
	margin-top: 0;
}
.mt-1{
	margin-top: 1rem;
}
.mb-0, .section .section-heading.mb-0, .section .section-heading .paragraph.mb-0, .ui-tabs .nav-tabs.mb-0, .section .section-heading .heading.mb-0, .ui-icon-blocks .ui-icon-block.mb-0{
	margin-bottom: 0;
}
.mb-2, .section .section-heading.mb-2{
	margin-bottom: 2rem;
}
.mb-3, .section .section-heading.mb-3{
	margin-bottom: 3rem;
}
.mb-4, .section .section-heading.mb-4{
	margin-bottom: 4rem;
}
.mb-1,  .section .section-heading .heading.mb-1{
	margin-bottom: 1rem;
}
.mt-2{
	margin-top: 2rem;
}
.mt-3{
	margin-top: 3rem;
}
.mt-4{
	margin-top: 4rem;
}